import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mailer-complete',
  templateUrl: './mailer-complete.component.html',
  styleUrls: ['./mailer-complete.component.css']
})
export class MailerCompleteComponent implements OnInit {

  constructor() { }

  orderNumber:string;
  ngOnInit() {
    this.orderNumber = JSON.parse(localStorage.getItem("preOrderModel")).OrderId;
  }

}
