import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { Router } from '@angular/router';
declare var $ :any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {


  gpdrChecked = false;

  constructor(private authenticationService:AuthenticationService, private router:Router) { }
  rePassword:string;
  user = new User();

  ngOnInit() {
    
      $('.telefon').mask('(000) 000-00-00',{placeholder:"(___) ___-__-__"});
      $('.vergino').mask('0000000000',{placeholder:"__________"});
    
  }


  register() 
  {

    if(this.gpdrChecked == false){
      alert("Gizlilik sözleşmesi kabul edilmelidir.");
      return;
    }

    if(this.user.Password != this.rePassword){
      alert("Girilen şifreler birbirinden farklı !");
      return;
    }

    if(this.user.TaxNumber === undefined || this.user.TaxNumber.length != 10){
      alert("Vergi numarası 10 haneli olmalıdır !");
      return;
    }


    if(this.validate() == false){
      return;
    }
    
    this.authenticationService.register(this.user).subscribe(data=>{
      if(data.Succeeded == true){
        this.router.navigate(['/register-completed']);
      }
      else
      {
        alert(JSON.stringify(data.Errors));
      }

    });
  }

  validate():boolean
  {
    if(this.user.AccountingPersonEmail === '' || this.user.AccountingPersonEmail === undefined){
      alert('Muhasebe yetkili kişi email adresi zorunludur');
      return false;
    }


    if(this.user.AccountingPersonNameSurname === '' || this.user.AccountingPersonNameSurname === undefined){
      alert('Muhasebe yetkili kişi adı zorunludur.');
      return false;
    }

    
    if(this.user.AccountingPersonPhone === '' || this.user.AccountingPersonPhone === undefined){
      alert('Muhasebe yetkili kişi telefon zorunldur.');
      return false;
    }

    
    if(this.user.Address === '' || this.user.Address === undefined){
      alert('Adres zorunludur.');
      return false;
    }
    
    if(this.user.City === '' || this.user.City === undefined){
      alert('İl zorunludur.');
      return false;
    }
    
    if(this.user.CompanyName === '' || this.user.CompanyName === undefined){
      alert('Şirket adı zorunludur.');
      return false;
    }
    
    if(this.user.Country === '' || this.user.Country === undefined){
      alert('Ülke zorunludur.');
      return false;
    }
    
    if(this.user.District === '' || this.user.District === undefined){
      alert('İlçe zorunludur.');
      return false;
    }
    
    if(this.user.Email === '' || this.user.Email === undefined){
      alert('Email adresi zorunludur.');
      return false;
    }
    
    
    if(this.user.Password === '' || this.user.Password === undefined){
      alert('Şifre zorunludur');
      return false;
    }
    
    if(this.user.PhoneNumber === '' || this.user.PhoneNumber === undefined){
      alert('Telefon numarası zorunludur.');
      return false;
    }
    

    if(this.user.TaxNumber === '' || this.user.TaxNumber === undefined){
      alert('Vergi numarası zorunludur.');
      return false;
    }
    
    if(this.user.TaxOffice === '' || this.user.TaxOffice === undefined){
      alert('Vergi dairesi zorunludur.');
      return false;
    }
    
    if(this.user.UserName === '' || this.user.UserName === undefined){
      alert('Kullanıcı adı zorunludur.');
      return false;
    }
  
    return true;

  }

}
