import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../models/Country';
import { ServiceBase } from '../inf/ServiceBase';
import { Language } from '../models/Language';

@Injectable({providedIn: 'root'})
export class LanguageService implements ServiceBase {
    constructor(private httpClient: HttpClient) { }
    
    getLanguages():Observable<Language[]>
    {
        return this.httpClient.get<Language[]>(ServiceBase.importerService+"Language");
    }

}