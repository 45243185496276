import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-mailer-login',
  templateUrl: './mailer-login.component.html',
  styleUrls: ['./mailer-login.component.css']
})
export class MailerLoginComponent implements OnInit {

 
  constructor(private authenticationService:AuthenticationService, private router:Router, private route:ActivatedRoute) { }
  username:string;
  password:string;
  returnUrl:string;
  loginSpinner:boolean;
  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login()
  {
    this.loginSpinner = true;
    this.authenticationService
                .authenticate(this.username,this.password)
                .subscribe(data=> {
                  this.loginSpinner = false;
                    var authResult = this.authenticationService.isAuthenticated();
                    if(authResult == true)
                    {
                      document.getElementById("modalCloseButton").click();
                    }
                },
                err=>{
                  this.loginSpinner = false;
                  alert("Kullanıcı adı veye şifre yanlış.");
                }
                );
  }

  ngOnDestroy(): void {
    document.getElementById("modalCloseButton").click();
  }

}
