declare var $ :any;

export class Init
{
    InitializeAccordion(){
        $('.accordion').each(function (i, elem) {
            var $elem = $(this),
                $acpanel = $elem.find(".acd-group > .acd-des"),
                $acsnav =  $elem.find(".acd-group > .acd-heading");
               $acpanel.hide().first().slideDown("easeOutExpo");
               $acsnav.first().parent().addClass("acd-active");
               $acsnav.on('click', function () {
                 if(!$(this).parent().hasClass("acd-active")){
                   var $this = $(this).next(".acd-des");
                   $acsnav.parent().removeClass("acd-active");
                   $(this).parent().addClass("acd-active");
                   $acpanel.not($this).slideUp("easeInExpo");
                   $(this).next().slideDown("easeOutExpo");
                 }else{
                    $(this).parent().removeClass("acd-active");
                    $(this).next().slideUp("easeInExpo");
                 }
                 return false;
             });
           });
    }

    InitializeDatatable(name:string){
      $('#'+name).DataTable();
    }

    InitializeCarousel(name:string){
      var owlslider = $("div.owl-carousel");
      if(owlslider.length > 0) {  

           owlslider.each(function () {
            var $this = $(this),
                $items = ($this.data('items')) ? $this.data('items') : 1,
                $loop = ($this.attr('data-loop')) ? $this.data('loop') : true,
                $navdots = ($this.data('nav-dots')) ? $this.data('nav-dots') : false,
                $navarrow = ($this.data('nav-arrow')) ? $this.data('nav-arrow') : false,
                $autoplay = ($this.attr('data-autoplay')) ? $this.data('autoplay') : true,
                $autospeed = ($this.attr('data-autospeed')) ? $this.data('autospeed') : 5000,
                $smartspeed = ($this.attr('data-smartspeed')) ? $this.data('smartspeed') : 1000,
                $autohgt = ($this.data('autoheight')) ? $this.data('autoheight') : false,
                $space = ($this.attr('data-space')) ? $this.data('space') : 30;    
           
                // $(this).owlCarousel({
                //     loop: $loop,
                //     items: $items,
                //     responsive: {
                //       0:{items: $this.data('xx-items') ? $this.data('xx-items') : 1},
                //       480:{items: $this.data('xs-items') ? $this.data('xs-items') : 1},
                //       768:{items: $this.data('sm-items') ? $this.data('sm-items') : 2},
                //       980:{items: $this.data('md-items') ? $this.data('md-items') : 3},
                //       1200:{items: $items}
                //     },
                //     dots: $navdots,
                //     autoplayTimeout:$autospeed,
                //     smartSpeed: $smartspeed,
                //     autoHeight:$autohgt,
                //     margin:$space,
                //     nav: $navarrow,
                //     navText:["<i class='fa fa-angle-left fa-2x'></i>","<i class='fa fa-angle-right fa-2x'></i>"],
                //     autoplay: $autoplay,
                //     autoplayHoverPause: true   
                // }); 
           }); 

      }
    }





}