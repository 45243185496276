export class MailerTestRequest
{
    Subject:string;
    SenderIdentity:string;
    SenderMailAddress:string;
    MailAddress:string;
    Content:string;
    UserId:string;
}


