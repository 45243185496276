import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Mailer, MailerStatusEnum } from 'src/app/models/Mailer';
import { MailerService } from 'src/app/services/mailer-service';
import { Router } from '@angular/router';
import { Sector } from 'src/app/models/sector';
import { ImporterService } from 'src/app/services/importer-service';
import { SectorService } from 'src/app/services/sector-service';
import * as sha1 from 'js-sha1';
import { PreOrderModel } from 'src/app/models/PreOrderModel';

@Component({
  selector: 'app-mailer-payment',
  templateUrl: './mailer-payment.component.html',
  styleUrls: ['./mailer-payment.component.css']
})
export class MailerPaymentComponent implements OnInit {

  @Output() next:EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() prev:EventEmitter<string> = new EventEmitter<string>();
  mailer:Mailer;

  constructor(private mailerService:MailerService, private router:Router, private sectorService:SectorService) { }

  totalAmount:number;
  discountRate:number;
  discountAmount:number;
  taxAmount:number;
  lastAmount:number;
  sectors:Sector[];
  public preOrderModel:PreOrderModel;
  ngOnInit() {
    
    this.mailer = JSON.parse(localStorage.getItem("mailer"));
    this.totalAmount =  Number(localStorage.getItem("totalAmount"));
    this.calculateClientSideDiscount();

    this.sectors = JSON.parse(localStorage.getItem("selectedSectorsAll"));

    this.preOrderModel = JSON.parse(localStorage.getItem("preOrderModel"));


  }

  nextStep()
  {
    this.next.emit(["",""]);
  }

  prevStep()
  {
    this.prev.emit("");
  }


  completePaymentWithBank()
  {
    var orderNumber = JSON.parse(localStorage.getItem("preOrderModel")).OrderId;
    this.mailerService.setWaitingPayment(orderNumber).subscribe(data=>{
      localStorage.removeItem("mailer");
      this.router.navigate(['/mailer-complete']);
    });    
  }

  calculateClientSideDiscount(){

    if(this.totalAmount < 6000)
    {
      this.discountRate = 0;
    }

    if(this.totalAmount >= 6000 && this.totalAmount < 8000)
    {
      this.discountRate = 6;
    }

    if(this.totalAmount >= 8000 && this.totalAmount < 10000)
    {
      this.discountRate = 8;
    }
    
    if(this.totalAmount >= 10000)
    {
      this.discountRate = 10;
    }

    this.discountAmount = (this.totalAmount * (this.discountRate / 100));

    this.taxAmount = ((this.totalAmount - this.discountAmount) * (18 / 100));

    this.lastAmount = (this.totalAmount - this.discountAmount) + this.taxAmount;
  }

}
