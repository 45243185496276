import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/Contact';
import { ContactService } from '../../services/contact-service'

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private contactService:ContactService) { }
  contact:Contact;
  ngOnInit() {
    this.clearContact();
  }

  sendContact()
  {
    if(this.contact.Description.trim() == "" || this.contact.Email.trim() == "" || this.contact.Name.trim() == "" ||  this.contact.Title.trim() == "")
    {
      alert("Lütfen tüm alanları doldurun !");
      return;
    }

    this.contactService.sendContact(this.contact).subscribe(response=>{
      this.clearContact();
      alert("İletim formu gönderimiştir.");
    });
  }

  clearContact()
  {
    this.contact = new Contact();
    this.contact.Title = "";
    this.contact.Name = "";
    this.contact.Email = "";
    this.contact.Description = "";
  }

}
