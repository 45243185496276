import { Component, OnInit } from '@angular/core';
import { MailerService } from 'src/app/services/mailer-service';
import { CanceledMailer } from 'src/app/models/CanceledMailer';

@Component({
  selector: 'app-canceled-mailer',
  templateUrl: './canceled-mailer.component.html',
  styleUrls: ['./canceled-mailer.component.css']
})
export class CanceledMailerComponent implements OnInit {

  constructor(private mailerService:MailerService) { }
  canceledMailers:CanceledMailer[];
  ngOnInit() {
    this.loadCanceledMailers();
  }

  loadCanceledMailers(){
    this.mailerService.getCanceledMailers().subscribe(data=>{
        this.canceledMailers = data;
    });
  }


}
