import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { Sector } from 'src/app/models/sector';
import { SectorService } from 'src/app/services/sector-service';
import { Init } from '../../inf/Init'
import { SwiperComponent, SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface, SwiperScrollbarInterface } from 'ngx-swiper-wrapper';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private authService:AuthenticationService, private sectorService:SectorService) { }
  sectors:Sector[];
  ngOnInit() {
    this.loadSectors();
    new Init().InitializeCarousel("carousel");
  }


  public show: boolean = true;

  public type: string = "component";

  public disabled: boolean = false;

  public config: SwiperConfigInterface = {
    direction: "horizontal",
    slidesPerView: 3,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: false,
    pagination: false,
    autoplay: true
  };



  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;

  isAuthenticated():boolean
  {
    return this.authService.isAuthenticated();
  }

  loadSectors(){
    this.sectorService.getSector().subscribe(data=>{
      this.sectors = data.filter(x=> x.ParentId == null);
    });
  }


}
