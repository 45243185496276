import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private authenticationService:AuthenticationService, private router:Router, private route:ActivatedRoute) { }
  username:string;
  password:string;
  returnUrl:string;
  loginSpinner:boolean;
  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login()
  {
    this.loginSpinner = true;
    this.authenticationService
                .authenticate(this.username,this.password)
                .subscribe(data=> {
                    this.loginSpinner = false;
                    var authResult = this.authenticationService.isAuthenticated();
                    if(authResult == true)
                    {
                      this.router.navigateByUrl(this.returnUrl);
                    }
                },
                err=>{
                  this.loginSpinner = false;
                  alert("Kullanıcı adı veye şifre yanlış.");
                }
                );
  }

}
