import { Component, OnInit } from '@angular/core';
import { MailerService } from 'src/app/services/mailer-service';
import { WaitingOnQueueMailer } from 'src/app/models/WaitingOnQueueMailer';
import { SectorService } from 'src/app/services/sector-service';
import { Sector } from 'src/app/models/sector';
import { ImporterService } from 'src/app/services/importer-service';

@Component({
  selector: 'app-waitingonqueue-mailer',
  templateUrl: './waitingonqueue-mailer.component.html',
  styleUrls: ['./waitingonqueue-mailer.component.css']
})
export class WaitingonqueueMailerComponent implements OnInit {

  constructor(private mailerService:MailerService, private sectorService:SectorService, private importerService:ImporterService) { }
  sectors:Sector[];
  importers:string[];

  ngOnInit() {

    this.loadSectors();

    this.loadUserWaitingOnQueueMailers();


  }

  loadSectors(){
    this.sectorService.getSector().subscribe(data=>{
      this.sectors = data;
    });
  }

  userWaitingOnQueueMailers:WaitingOnQueueMailer[];

  loadUserWaitingOnQueueMailers(){
    this.mailerService.getUserMailers().subscribe(data=>{
        this.userWaitingOnQueueMailers = data.filter(x=> x.Status != 1);
    });
  }

  getSectorName(id:number):string
  {
    return this.sectors.find(x => x.Id === id).Name;
  }

  showImporters(id:number){
    this.importerService.getImporterBySectorId(id).subscribe(data=>{
      this.importers = data;
      document.getElementById("showImporterModal").click();
    });
  }

}
