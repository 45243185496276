import { Component } from '@angular/core';
import { Init } from './inf/Init';
import { TranslateService } from '@ngx-translate/core';

import {locale as enLang} from '../app/inf/i18n/en';
import {locale as trLang} from '../app/inf/i18n/tr';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'B2BMatcherAngular';


  constructor(translate: TranslateService) {
    translate.setDefaultLang("1");
    translate.use("1");
  }

  ngAfterViewInit()
  {
    new Init().InitializeAccordion();
    new Init().InitializeCarousel("carousel");
  }

}

