import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sector } from '../models/sector';
import { Importer } from '../models/Importer';
import { ImporterSearchRequest } from '../models/ImporterSearchRequest';
import { ServiceBase } from '../inf/ServiceBase';
import { BannedImporter } from '../models/BannedImporter';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({providedIn: 'root'})
export class ImporterService implements ServiceBase {

    jwtHelper = new JwtHelperService();

    constructor(private httpClient: HttpClient) 
    { 

    }
    
    getImporters(importerSearchRequest:ImporterSearchRequest):Observable<Importer[]>
    {
        return this.httpClient.post<Importer[]>(ServiceBase.importerService+ "Importer/search", importerSearchRequest);
    }

    getImporterBySectorId(id:number):Observable<string[]>
    {
        return this.httpClient.get<string[]>(ServiceBase.importerService+ "Importer/GetImporterBySectorId?sectorId="+id);
    }

    getBannedImporters():Observable<BannedImporter[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<BannedImporter[]>(ServiceBase.importerService+ "BannedImporter/"+userId);
    }

    banImporter(importerId:number):Observable<BannedImporter>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        var importerBan = new BannedImporter();
        importerBan.ImporterId = importerId;
        importerBan.UserId = userId;
        return this.httpClient.post<BannedImporter>(ServiceBase.importerService+ "BannedImporter",importerBan);
    }

    removeBanFromImporter(importerId:number):Observable<BannedImporter>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        var importerBan = new BannedImporter();
        importerBan.ImporterId = importerId;
        importerBan.UserId = userId;
        return this.httpClient.delete<BannedImporter>(ServiceBase.importerService+ "BannedImporter/"+userId+"/"+importerId+"/",);
    }

    


 
}