import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication-service.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  constructor(private authenticationService:AuthenticationService, private router:Router) { }

  ngOnInit() {
  }
  
  public email:string;

  passwordRecovery(){
    this.authenticationService.passwordRecovery(this.email).subscribe(data=>{
      alert("Şifre sıfırlama mailiniz gönderildi.");
      this.router.navigate(["/index"]);
    });
  }
  

}
