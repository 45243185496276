import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/User';
import { AuthenticationService } from 'src/app/services/authentication-service.service';


@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {

  constructor(private authenticationService:AuthenticationService) { }
  currentUser:User;
  ngOnInit() {
    this.loadUser();
  }

  loadUser()
  {
    this.authenticationService.getCurrentUser().subscribe(data=>{
      this.currentUser = data;
    });
  }

  updateUserInfo(){
    this.authenticationService.updateUserInformation(this.currentUser).subscribe(data=>{
      console.log(data);
    });
  }

}
