import { Component, OnInit } from '@angular/core';
import { PasswordUpdateModel } from 'src/app/models/PasswordUpdateModel';
import { AuthenticationService } from 'src/app/services/authentication-service.service';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.css']
})
export class UserChangePasswordComponent implements OnInit {

  constructor(private authenticationService:AuthenticationService) { }
  passwordUpdateModel = new PasswordUpdateModel();
  ngOnInit() {
  }

  updatePassword(){
    this.authenticationService.updatePassword(this.passwordUpdateModel).subscribe(data=>{
      alert("Şifre güncellendi.");
    });

  }

}
