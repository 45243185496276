import { Component, OnInit } from '@angular/core';
import { MailerService } from 'src/app/services/mailer-service';
import { WaitingApprovalMailer } from 'src/app/models/WaitingApprovalMailer';

@Component({
  selector: 'app-waitingapproval-mailer',
  templateUrl: './waitingapproval-mailer.component.html',
  styleUrls: ['./waitingapproval-mailer.component.css']
})
export class WaitingapprovalMailerComponent implements OnInit {

  constructor(private mailerService:MailerService) { }
  waitingApprovalMailers:WaitingApprovalMailer[];
  ngOnInit() {
    this.loadWaitingApprovalMailers();
  }


  loadWaitingApprovalMailers(){
    this.mailerService.getSendingMailers().subscribe(data=>{
        this.waitingApprovalMailers = data;
    });
  }

}
