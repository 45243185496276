import { Component, OnInit } from '@angular/core';
import { MailerService } from 'src/app/services/mailer-service';
import { SendingMailer } from 'src/app/models/SendingMailer';

@Component({
  selector: 'app-sending-mailer',
  templateUrl: './sending-mailer.component.html',
  styleUrls: ['./sending-mailer.component.css']
})
export class SendingMailerComponent implements OnInit {

  constructor(private mailerService:MailerService) { }

  sendingMailers:SendingMailer[];

  ngOnInit() {
    this.loadUserSendingMailers();
  }



  loadUserSendingMailers(){
    this.mailerService.getSendingMailers().subscribe(data=>{
        this.sendingMailers = data;
    });
  }

}
