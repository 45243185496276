import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-register-completed',
  templateUrl: './register-completed.component.html',
  styleUrls: ['./register-completed.component.css']
})
export class RegisterCompletedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
