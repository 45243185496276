import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/User';
import { Authentication } from '../models/Authentication';
import {JwtHelperService} from '@auth0/angular-jwt'
import { Router } from '@angular/router';
import { ServiceBase } from '../inf/ServiceBase';
import { PasswordUpdateModel } from '../models/PasswordUpdateModel';
import { PasswordResetModel } from '../models/PasswordResetModel';




@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements ServiceBase {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  jwtHelper = new JwtHelperService();
  private _username:string;
  toastsSerivce: any;
  translateService: any;

  constructor(private httpClient: HttpClient, private router:Router) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }


public authenticate(username:string, password:string):Observable<any>
{
  
    var auth = new Authentication;
    auth.client_id = "ro.angular";
    auth.client_secret = "secret";
    auth.grant_type = "password";
    auth.scope = "api1 openid";
    auth.password = password;
    auth.username = username;
    this._username = username;
  let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  let body = new URLSearchParams();
  body.set('username', username);
  body.set('password', password);
  body.set('grant_type', "password");
  body.set('scope', "api1 openid");
  body.set('client_id', "ro.angular");
  body.set('client_secret', "secret");

return this.httpClient.post<any>(ServiceBase.authenticationService+"connect/token", body.toString(), 
{
  headers: headers
}).pipe( map (jwt => {
  if(jwt && jwt.access_token){
    localStorage.setItem('username',this._username);
    localStorage.setItem('token',JSON.stringify(jwt))
  }
}));
}


isAuthenticated() {
  const token = localStorage.getItem('token');
  return !this.jwtHelper.isTokenExpired(token);
}

getToken() {
  return this.jwtHelper.tokenGetter();
}

logout() {
  localStorage.removeItem('token');
  this.router.navigate(['/index']);
}

register(user:User):Observable<any>
{

  return this.httpClient.post<any>(ServiceBase.identityService+"Register",user);
}

getCurrentUser():Observable<User>
{
  const token = localStorage.getItem('token');
  var userId = this.jwtHelper.decodeToken(token).sub;
  return this.httpClient.get<User>(ServiceBase.identityService+"User/"+userId)
}

updateUserInformation(user:User):Observable<any>
{
  return this.httpClient.post<any>(ServiceBase.identityService+"User",user);
}


updatePassword(passwordUpdateModel:PasswordUpdateModel):Observable<any>
{
  const token = localStorage.getItem('token');
  var userId = this.jwtHelper.decodeToken(token).sub;
  passwordUpdateModel.UserId = userId;
  return this.httpClient.post<any>(ServiceBase.identityService+"User/updatepassword",passwordUpdateModel);
}

passwordRecovery(email:string):Observable<any>
{
  return this.httpClient.get<any>(ServiceBase.identityService+"EmailRecovery/"+email);
}

passwordReset(model:PasswordResetModel):Observable<any>
{
  return this.httpClient.post<any>(ServiceBase.identityService+"EmailRecovery/",model);
}


}
