import { Component, OnInit } from '@angular/core';
import { CompletedMailer } from 'src/app/models/CompletedMailer';
import { MailerService } from 'src/app/services/mailer-service';

@Component({
  selector: 'app-completed-mailer',
  templateUrl: './completed-mailer.component.html',
  styleUrls: ['./completed-mailer.component.css']
})
export class CompletedMailerComponent implements OnInit {

  constructor(private mailerService:MailerService) { }
  completedMailers:CompletedMailer[];
  ngOnInit() {
    this.loadCompletedMailers();
  }

  loadCompletedMailers(){
    this.mailerService.getCompletedMailers().subscribe(data=>{
        this.completedMailers = data;
    });
  }

}
