export class User
{
    Id:number;
    FirstName: string;
    LastName: string;
    CompanyName: string;
    Address: string;
    PostCode: string;
    Country: string;
    City: string;
    District: string;
    State: string;
    TaxOffice: string;
    TaxNumber: string;
    Email:string;
    PhoneNumber:string;
    FaxNumber: string;
    AccountingPersonNameSurname: string;
    AccountingPersonPosition: string;
    AccountingPersonPhone: string;
    AccountingPersonEmail: string;
    AuthorizedPersonNameSurname: string;
    AuthorizedPersonPosition: string;
    AuthorizedPersonPhone: string;
    AuthorizedPersonEmail: string;
    Password: string;
    UserName:string;
}



