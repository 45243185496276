import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Sector } from 'src/app/models/sector';
import { SectorService } from 'src/app/services/sector-service';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-select-sector',
  templateUrl: './select-sector.component.html',
  styleUrls: ['./select-sector.component.css']
})
export class SelectSectorComponent implements OnInit {
  totalAmount:number;
  sectors:Sector[];
  selectedSectors:Sector[] = new Array<Sector>();
  sectorForm: FormGroup;
  defaultSectors:Sector[];
  @Output() next:EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(private sectorService:SectorService) { }
  selectedIds:string[];
  ngOnInit() {
    this.selectedIds = [];
    this.totalAmount = 0;
    this.getSectors();
  }

  getSectors()
  {
    this.sectorService.getSector().subscribe(data=>{
      this.defaultSectors = data;
      this.sectors = data.filter(x=> x.ParentId == null);

      this.sectors.forEach(x=> {
        x.SubSectors = data.filter(y=> y.ParentId == x.Id.toString())
      });

    });
  }
  
  onChange(sector: Sector, isChecked: boolean) {
    if (isChecked) {
      this.selectedIds.push(sector.Id.toString());
      this.selectedSectors.push(sector);
    } else {
      this.selectedIds = this.selectedIds.filter(x=> x != sector.Id.toString());
      this.selectedSectors = this.selectedSectors.filter(x=> x.Id != sector.Id);
    }

    localStorage.setItem("selectedSectorsAll",JSON.stringify(this.selectedSectors));

    this.calculateTotalPriceWithPromotion();
  }
  
  calculateTotalPriceWithPromotion() 
  {
    let selectedSectors = this.defaultSectors.filter(x=> this.selectedIds.includes(x.Id.toString()));

    this.totalAmount = selectedSectors.reduce((sum,current)=> sum + current.Price, 0);

    localStorage.setItem("totalAmount",this.totalAmount.toString());

    this.sectors.forEach(x=> x.SubSectors.forEach(y=> y.IsPromotion = false));

  }

  nextStep(){

    if(this.selectedIds.length == 0){
      alert("Sektör seçimi yapınız !");
      return;
    }

    this.next.emit(this.selectedIds);
      
  }



 

}

