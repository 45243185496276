export class ServiceBase {

    
    // public static authenticationService:string = "http://localhost:5010/";
    // public static identityService:string = "http://localhost:5010/api/";
    // public static importerService:string = "http://localhost:5020/api/";

    public static authenticationService:string = "https://identity-api.b2bmatcher.com/";
    public static identityService:string = "https://identity-api.b2bmatcher.com/api/";
    public static importerService:string = "https://importer-api.b2bmatcher.com/api/";
    public static mailerService:string = "https://mailer-api.b2bmatcher.com/api/";
   
    

}