import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from '../inf/ServiceBase';
import { Mailer } from '../models/Mailer';
import { Contact } from '../models/Contact';
import { HttpClient } from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class ContactService implements ServiceBase {
    constructor(private httpClient:HttpClient) { }
    

    sendContact(contact:Contact):Observable<Contact>
    {
        return this.httpClient.post<Contact>(ServiceBase.mailerService+"Contact",contact);
    }
}