import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PasswordResetModel } from '../../models/PasswordResetModel';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  userId:string;
  token:string;
  public newPassword:string;

  constructor(private route:ActivatedRoute, private authenticationService:AuthenticationService, private router:Router) 
  {
    this.route.params.subscribe(params=> {
      this.userId = params.userId
      this.token = params.token
    });
  }

  resetPassword()
  {
    let passwordResetModel = new PasswordResetModel();
    passwordResetModel.NewPassword = this.newPassword;
    passwordResetModel.Token = this.token;
    passwordResetModel.UserId = this.userId;

    this.authenticationService.passwordReset(passwordResetModel).subscribe(response=>{
      if(response.Succeeded)
      {
        alert("Şifreniz değiştirilmiştir.");
        this.router.navigate(["/index"]);
      }else
      {
        alert(JSON.stringify(response.Errors));
      }
    });
  }

  ngOnInit() 
  {

  }


}
