import { Component, OnInit } from '@angular/core';
import { MailerService } from 'src/app/services/mailer-service';
import { WaitingPaymentMailer } from 'src/app/models/WaitingPaymentMailer';

@Component({
  selector: 'app-waitingpayment-mailer',
  templateUrl: './waitingpayment-mailer.component.html',
  styleUrls: ['./waitingpayment-mailer.component.css']
})
export class WaitingpaymentMailerComponent implements OnInit {

  constructor(private mailerService:MailerService) { }
  waitingPaymentMailers:WaitingPaymentMailer[];
  ngOnInit() {
    this.loadWaitingPaymentsMailers();
  }

  loadWaitingPaymentsMailers(){
    this.mailerService.getWaitingPaymentMailers().subscribe(data=>{
        this.waitingPaymentMailers = data;
    });
  }

}
