import { MailerSummary } from './MailerSummary';

export class Mailer
{
    Id:number;
    UserId:string;
    SenderIdentity: string;
    SenderMailAdress: string;
    Attachments: string[];
    Title:string;
    Content: string;
    Sectors: number[];
    Countries: number[];
    CreatedDate:Date;
    Status: MailerStatusEnum;
    BannedImporters: number[];
    SaveDraft:boolean;
    MailerSummary:MailerSummary;
}



export enum MailerStatusEnum {
    Draft = 1,
    PaymentWaiting = 2,
    Queue = 3,
    Sending = 4,
    Completed = 5,
    Canceled = 6,
    WaitingForApproval = 7

}