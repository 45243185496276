import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from './services/authentication-service.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SelectSectorComponent } from './pages/mailer-steps/select-sector/select-sector.component';
import { SelectCountryComponent } from './pages/mailer-steps/select-country/select-country.component';
import { SelectBannedCompanyComponent } from './pages/mailer-steps/select-banned-company/select-banned-company.component';
import { GenerateMailComponent } from './pages/mailer-steps/generate-mail/generate-mail.component';
import { MailerLoginComponent } from './pages/mailer-steps/mailer-login/mailer-login.component';
import { MailerRegisterComponent } from './pages/mailer-steps/mailer-register/mailer-register.component';
import { MailerPaymentComponent } from './pages/mailer-steps/mailer-payment/mailer-payment.component';
import { MailerCompleteComponent } from './pages/mailer-steps/mailer-complete/mailer-complete.component';
import { MailerComponent } from './pages/mailer-steps/mailer/mailer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTreeModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatTableModule } from '@angular/material';

import { ImporterService } from './services/importer-service';
import { CountryService } from './services/country-service';
import { RegionService } from './services/region-service';
import { SectorService } from './services/sector-service';
import { JwtModule } from '@auth0/angular-jwt';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AuthGuard } from './guards/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterCompletedComponent } from './pages/register-completed/register-completed.component';
import { UserInfoComponent } from './pages/user-profile-pages/user-info/user-info.component';
import { SendingMailerComponent } from './pages/user-profile-pages/sending-mailer/sending-mailer.component';
import { CompletedMailerComponent } from './pages/user-profile-pages/completed-mailer/completed-mailer.component';
import { BannedImportersComponent } from './pages/user-profile-pages/banned-importers/banned-importers.component';
import { UserHelpComponent } from './pages/user-profile-pages/user-help/user-help.component';
import { UserChangePasswordComponent } from './pages/user-profile-pages/user-change-password/user-change-password.component';
import { DraftMailerComponent } from './pages/user-profile-pages/draft-mailer/draft-mailer.component';
import { MailerService } from './services/mailer-service';
import { NgxTinymceModule } from 'ngx-tinymce';
import { MailerPreviewComponent } from './pages/mailer-steps/mailer-preview/mailer-preview.component';
import {CardModule} from 'ngx-card/ngx-card';
import { WaitingapprovalMailerComponent } from './pages/user-profile-pages/waitingapproval-mailer/waitingapproval-mailer.component';
import { WaitingpaymentMailerComponent } from './pages/user-profile-pages/waitingpayment-mailer/waitingpayment-mailer.component';
import { CanceledMailerComponent } from './pages/user-profile-pages/canceled-mailer/canceled-mailer.component';
import { WaitingonqueueMailerComponent } from './pages/user-profile-pages/waitingonqueue-mailer/waitingonqueue-mailer.component';

import { HashLocationStrategy,LocationStrategy } from '@angular/common';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ContactComponent } from './pages/contact/contact.component';
import { ContactService } from './services/contact-service';
import { Observable } from 'rxjs';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { LanguageService } from './services/language-service';

import { NgxEditorModule } from 'ngx-editor';
import {DataTableModule} from 'angular-6-datatable';
import { SwiperModule, SwiperConfigInterface,
  SWIPER_CONFIG } from 'ngx-swiper-wrapper';

  const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 3,
    centeredSlides: true
  };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    PasswordRecoveryComponent,
    HeaderComponent,
    FooterComponent,
    SelectSectorComponent,
    SelectCountryComponent,
    SelectBannedCompanyComponent,
    GenerateMailComponent,
    MailerLoginComponent,
    MailerRegisterComponent,
    MailerPaymentComponent,
    MailerCompleteComponent,
    MailerComponent,
    UserProfileComponent,
    RegisterCompletedComponent,
    UserInfoComponent,
    SendingMailerComponent,
    CompletedMailerComponent,
    BannedImportersComponent,
    UserHelpComponent,
    UserChangePasswordComponent,
    DraftMailerComponent,
    MailerPreviewComponent,
    WaitingapprovalMailerComponent,
    WaitingpaymentMailerComponent,
    CanceledMailerComponent,
    WaitingonqueueMailerComponent,
    PasswordResetComponent,
    ContactComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    DataTableModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTreeModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatTableModule,
    FormsModule,
    CardModule,
    NgxEditorModule,
    SwiperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxTinymceModule.forRoot({
      // baseURL: './assets/tinymce/',
      // or cdn
      baseURL: '//cdnjs.cloudflare.com/ajax/libs/tinymce/4.9.0/',
      config:{
        plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media textcolor template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help',
        toolbar: 'fontselect |  fontsizeselect forecolor backcolor formatselect | bold italic strikethrough permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',

      }
    }),
    JwtModule.forRoot({
      config:{
        tokenGetter:tokenGetter
      }
    })
  ],
  providers: [LanguageService,ContactService,AuthenticationService,HttpClient,ImporterService,CountryService,RegionService,SectorService, AuthGuard,MailerService, {provide:LocationStrategy, useClass:HashLocationStrategy},
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem('token');
}
 
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,"https://importer-api.b2bmatcher.com/api/LocalizationItem/localization/","");
} 