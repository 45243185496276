import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication-service.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  constructor(private authService:AuthenticationService) { }
  username:string;
  ngOnInit() {
    this.username = localStorage.getItem("username");
  }

  logout()
  {
    this.authService.logout();
  }

}
