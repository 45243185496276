import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../models/Country';
import { ServiceBase } from '../inf/ServiceBase';
import { Mailer } from '../models/Mailer';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserDraftMailer } from '../models/UserDraftMailer';
import { WaitingOnQueueMailer } from '../models/WaitingOnQueueMailer';
import { SendingMailer } from '../models/SendingMailer';
import { WaitingApprovalMailer } from '../models/WaitingApprovalMailer';
import { WaitingPaymentMailer } from '../models/WaitingPaymentMailer';
import { CompletedMailer } from '../models/CompletedMailer';
import { CanceledMailer } from '../models/CanceledMailer';
import { MailerSummaryRequest } from '../models/MailerSummaryRequest';
import { MailerSummary } from '../models/MailerSummary';
import { MailerTestRequest } from '../models/MailerTestRequest';
import { PreOrderModel } from '../models/PreOrderModel';
declare const $: any;

@Injectable({providedIn: 'root'})
export class MailerService implements ServiceBase {
    constructor(private httpClient: HttpClient) { }
    jwtHelper = new JwtHelperService();
    sendMailer(mailer:Mailer):Observable<PreOrderModel>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        mailer.UserId = userId;
        return this.httpClient.post<PreOrderModel>(ServiceBase.mailerService+"Mailer",mailer);
    }

    getUserDraftMailers():Observable<UserDraftMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<UserDraftMailer[]>(ServiceBase.mailerService+"UserDraftMailer/"+userId);
    }

    getUserMailers():Observable<UserDraftMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<UserDraftMailer[]>(ServiceBase.mailerService+"Mailer/userId/"+userId);
    }

    deleteUserDraftMailer(id:number):Observable<any>
    {
        return this.httpClient.delete<any>(ServiceBase.mailerService+"UserDraftMailer/"+id);
    }

    getWaitingOnQueueMailers():Observable<WaitingOnQueueMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<WaitingOnQueueMailer[]>(ServiceBase.mailerService+"WaitingOnQueueMailer/"+userId);
    }

    getSendingMailers():Observable<SendingMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<SendingMailer[]>(ServiceBase.mailerService+"SendingMailer/"+userId);
    }

    getWaitingApprovalMailers():Observable<WaitingApprovalMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<WaitingApprovalMailer[]>(ServiceBase.mailerService+"WaitingApprovalMailer/"+userId);
    }

    getWaitingPaymentMailers():Observable<WaitingPaymentMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<WaitingPaymentMailer[]>(ServiceBase.mailerService+"WaitingPaymentMailer/"+userId);
    }

    getCompletedMailers():Observable<CompletedMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<CompletedMailer[]>(ServiceBase.mailerService+"CompletedMailer/"+userId);
    }

    getCanceledMailers():Observable<CanceledMailer[]>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<CanceledMailer[]>(ServiceBase.mailerService+"CanceledMailer/"+userId);
    }

    getMailerSummary(mailerSummaryRequest:MailerSummaryRequest):Observable<MailerSummary>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        mailerSummaryRequest.UserId = userId;
        return this.httpClient.post<MailerSummary>(ServiceBase.mailerService+"MailerSummary",mailerSummaryRequest);
    }   
    
    setWaitingPayment(mailerId:number):Observable<boolean>
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        return this.httpClient.get<boolean>(ServiceBase.mailerService+"Mailer/changestatus/"+mailerId+"/2");
    }    

    testMailer(mailerTestRequest:MailerTestRequest)
    {
        var token = localStorage.getItem("token");
        var userId = this.jwtHelper.decodeToken(token).sub;
        mailerTestRequest.UserId = userId;
        return this.httpClient.post<MailerSummary>(ServiceBase.mailerService+"MailerTest",mailerTestRequest);
    }

    // tslint:disable-next-line:member-ordering
    public progress: number;
    // tslint:disable-next-line:member-ordering
    public message: string;


    upload(files):Observable<any>
     {
        if (files.length === 0)
          return;
    
        const formData = new FormData(); 
    
        for (let file of files)
          formData.append(file.name, file);
    
        const uploadReq = new HttpRequest('POST', ServiceBase.mailerService + 'Mailer/UploadFile', formData, {
          reportProgress: true,
        });
    
      
    
        return this.httpClient.request(uploadReq);
    }
    
    
      showNotification(from:string,align:string,type:string,message:string){
        $.notify({
          message: 'Welcome to <b>MATERIAL DASHBOARD</b> - a beautiful dashboard for every web developer.',
        }, {
          placement: {from, align},
          offset: {x: 20, y: 35},
          type,
          template: `<div class="alert alert-{0} alert-with-icon col-md-4">
              <i class="material-icons alert-icon">notifications</i>
              <button class="close" type="button" data-dismiss="alert" aria-label="Close"><i class="material-icons">close</i></button>
              <span style="font-weight:bold;">`+message+`</span>
            </div>`
        });
      }


      





}