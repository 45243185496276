import { Component, OnInit } from '@angular/core';
import { Init } from 'src/app/inf/Init';
import { Importer } from 'src/app/models/Importer';
import { ImporterService } from 'src/app/services/importer-service';
import { BannedImporter } from 'src/app/models/BannedImporter';

@Component({
  selector: 'app-banned-importers',
  templateUrl: './banned-importers.component.html',
  styleUrls: ['./banned-importers.component.css']
})
export class BannedImportersComponent implements OnInit {

  constructor(
    private importerService:ImporterService
  ) { }
  importers:BannedImporter[];
  ngOnInit() {
    new Init().InitializeDatatable("datatable");
    this.loadBannedImporters();
  }

  loadBannedImporters()
  {
    this.importerService.getBannedImporters().subscribe(data=>{
      this.importers = data;
    });
  }

  removeBanFromImporter(importer:BannedImporter)
  {
    this.importerService.removeBanFromImporter(importer.ImporterId).subscribe(data=>{
      this.importers = this.importers.filter(x=> x.ImporterId != importer.ImporterId);
    });
  }

  

}
