import { Component, OnInit } from '@angular/core';
import { UserDraftMailer } from 'src/app/models/UserDraftMailer';
import { MailerService } from 'src/app/services/mailer-service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-draft-mailer',
  templateUrl: './draft-mailer.component.html',
  styleUrls: ['./draft-mailer.component.css']
})
export class DraftMailerComponent implements OnInit {

  constructor(private mailerService:MailerService, private router:Router) { }

  userDraftMailers:UserDraftMailer[];

  ngOnInit() {
    this.loadUserDraftMailers();
  }

  loadUserDraftMailers(){
    this.mailerService.getUserDraftMailers().subscribe(data=>{
        this.userDraftMailers = data;
    });
  }

  editDraftMail(draftMail:UserDraftMailer)
  {
    localStorage.setItem("mailer",JSON.stringify(draftMail));
    this.router.navigate(['/mailer-steps/select-sector']);
  }

  deleteDraftMail(id:number)
  {
    this.mailerService.deleteUserDraftMailer(id).subscribe(data=>{
      this.userDraftMailers = this.userDraftMailers.filter(x=> x.Id != id);
  });
  }

  

}
