import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Region } from '../models/Region';
import { Observable } from 'rxjs';
import { ServiceBase } from '../inf/ServiceBase';

@Injectable({providedIn: 'root'})
export class RegionService implements ServiceBase {
    constructor(private httpClient: HttpClient) { }
    
    getRegion():Observable<Region[]>
    {
        return this.httpClient.get<Region[]>(ServiceBase.importerService+"Region");
    }

}