import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Init } from '../../../inf/Init'
import { RegionService } from 'src/app/services/region-service';
import { CountryService } from 'src/app/services/country-service';
import { Region } from 'src/app/models/Region';
import { ImporterService } from 'src/app/services/importer-service';
import { ImporterSearchRequest } from 'src/app/models/ImporterSearchRequest';

@Component({
  selector: 'app-select-country',
  templateUrl: './select-country.component.html',
  styleUrls: ['./select-country.component.css']
})
export class SelectCountryComponent implements OnInit {

  @Output() next:EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() prev:EventEmitter<string> = new EventEmitter<string>();

  
  constructor(private regionService:RegionService,private countryService:CountryService, private importerService:ImporterService) { }
  regions:Region[];

  selectedIds:string[];
  ngOnInit() {
    this.selectedIds = [];
    this.getRegionWithCountry();
  }

  getRegionWithCountry()
  {
    this.countryService.getCountry().subscribe(countriesResult=>{
      this.selectedIds = countriesResult.map(x=> x.Id.toString());
      this.regionService.getRegion().subscribe(regionsResult=>{
        regionsResult.forEach(r=>{
          r.Countries = countriesResult.filter(x=> x.RegionId == r.Id);
        });

        this.regions = regionsResult;

      });
    });
  } 

  onChange(id: string, isChecked: boolean) {
    
    if (isChecked) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds = this.selectedIds.filter(x=> x != id);
    }
  }

  nextStep(){
    if(this.selectedIds.length == 0){
      alert("Ülke seçimi yapınız !");
      return;
    }
    this.next.emit(this.selectedIds);
  }

  prevStep(){
    this.prev.emit("");
  }

}
