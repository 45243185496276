import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Mailer } from 'src/app/models/Mailer';
import { MailerSummary } from 'src/app/models/MailerSummary';
import { MailerService } from 'src/app/services/mailer-service';
import { MailerSummaryRequest } from 'src/app/models/MailerSummaryRequest';

@Component({
  selector: 'app-mailer-preview',
  templateUrl: './mailer-preview.component.html',
  styleUrls: ['./mailer-preview.component.css']
})
export class MailerPreviewComponent implements OnInit {

  public mailer:Mailer;
  public mailerSummary:MailerSummary;
  @Output() next:EventEmitter<Mailer> = new EventEmitter<Mailer>();
  @Output() prev:EventEmitter<string> = new EventEmitter<string>();
  constructor(private mailerService:MailerService) 
  { 
    
  }

  ngOnInit() {
    this.mailer = JSON.parse(localStorage.getItem("mailer"));
    this.loadMailerSummary();
  }

  nextStep()
  {
    this.mailer.MailerSummary = this.mailerSummary;
    this.next.emit(this.mailer);
  }

  prevStep()
  {
    this.prev.emit("");
  }

  loadMailerSummary()
  {
    var mailerSummaryRequest = new MailerSummaryRequest();
    mailerSummaryRequest.Countries = this.mailer.Countries;
    mailerSummaryRequest.Sectors = this.mailer.Sectors;
    this.mailerService.getMailerSummary(mailerSummaryRequest).subscribe(data=>{
      this.mailerSummary = data;
    });
  }

}










