import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sector } from '../models/sector';
import { ServiceBase } from '../inf/ServiceBase';

@Injectable({providedIn: 'root'})
export class SectorService implements ServiceBase {
    constructor(private httpClient: HttpClient) { }
    
    getSector():Observable<Sector[]>
    {
        return this.httpClient.get<Sector[]>(ServiceBase.importerService+"Sector");
    }

    getSectorById(id:number):Observable<Sector>
    {
        return this.httpClient.get<Sector>(ServiceBase.importerService+"Sector/"+id);
    }

}