import { Component, OnInit } from '@angular/core';
import { ImporterSearchRequest } from 'src/app/models/ImporterSearchRequest';
import { Importer } from 'src/app/models/Importer';
import { ImporterService } from 'src/app/services/importer-service';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Mailer, MailerStatusEnum } from 'src/app/models/Mailer';
import { MailerService } from 'src/app/services/mailer-service';
declare var $ :any;

@Component({
  selector: 'app-mailer',
  templateUrl: './mailer.component.html',
  styleUrls: ['./mailer.component.css']
})
export class MailerComponent implements OnInit {

  currentStep:number;
  importerSearchRequest:ImporterSearchRequest;
  importers:Importer[];
  mailer:Mailer;
  constructor(private importerService:ImporterService, private authenticationService:AuthenticationService, private router:Router, private route:ActivatedRoute, private mailerService:MailerService) { }

  steps:{[key:string]:number} = {
    "/mailer-steps/select-sector":1,
    "/mailer-steps/select-country":2,
    "/mailer-steps/select-banned-company":3,
    "/mailer-steps/generate-mail":4,
    "/mailer-steps/mailer-preview":5
  };

  ngOnInit() {
    this.importerSearchRequest = new ImporterSearchRequest();
    this.currentStep = this.getCurrentStepFromUrl();

    var localMail = localStorage.getItem("mailer");
    if(localMail != null)
    {
      this.mailer = JSON.parse(localMail);
      this.importerSearchRequest.SectorIdList = this.mailer.Sectors;
      this.importerSearchRequest.CountryIdList = this.mailer.Countries;
    }
  }

  nextStep2(selectedSectors:number[])
  {
    this.importerSearchRequest.SectorIdList = selectedSectors;
    this.currentStep = 2;
    this.updateMailer();
    this.router.navigate(['/mailer-steps/select-country']);
  }

  nextStep3(selectedCountries:number[])
  {
    if(this.authenticationService.isAuthenticated() == false)
    {
      document.getElementById("openModalButton").click();
      return;
    }

    
    this.importerSearchRequest.CountryIdList = selectedCountries;
    this.currentStep = 3;
    this.updateMailer();
    this.router.navigate(['/mailer-steps/select-banned-company']);

    this.importerService.getImporters(this.importerSearchRequest).subscribe(data=>{
        this.importers = data;
        this.markBannedImporter();
    }); 

  }

  markBannedImporter()
  {
    this.importerService.getBannedImporters().subscribe(bannedImporters=>{
      this.importers.forEach(x=>{
        var finded = bannedImporters.find(y=>y.ImporterId == x.Id);
        if(finded != null)
        {
          x.IsBanned = true;
        }
      });
      this.importerSearchRequest.BannedImporterIdList = bannedImporters.map(x=> x.ImporterId);
    });
  }

  nextStep4(importerIdList:string[])
  {
    this.currentStep = 4;
    this.updateMailer();
    this.router.navigate(['/mailer-steps/generate-mail']);
  }

  nextStep5(mailer:Mailer)
  {
    this.currentStep = 5;
    mailer.Sectors = this.importerSearchRequest.SectorIdList;
    mailer.Countries = this.importerSearchRequest.CountryIdList;
    mailer.Status = MailerStatusEnum.WaitingForApproval;

    this.mailerService.sendMailer(mailer).subscribe(data=>{

      localStorage.setItem("preOrderModel",JSON.stringify(data));

      if(mailer.SaveDraft == true)
      {
        mailer.Status = MailerStatusEnum.Draft;
        this.mailerService.sendMailer(mailer).subscribe(data=>{
          this.router.navigate(['/mailer-steps/mailer-payment']);
          this.currentStep = 6;
        });
      }else
      {
        this.router.navigate(['/mailer-steps/mailer-payment']);
        this.currentStep = 6;
      }

    });

  }

  nextStep6(selectedSectors:string[])
  {
    this.currentStep = 6;
    
    this.router.navigate(['/mailer-steps/mailer-payment']);
  }

  getCurrentStepFromUrl():number
  {
    return this.steps[this.router.url];
  }

  updateMailer()
  {
    localStorage.setItem("mailer",JSON.stringify(this.importerSearchRequest));
  }


  prevStep(content:string){

    if(this.currentStep == 6)
    {
      this.currentStep = 4;
    }else{
      this.currentStep = this.currentStep - 1;
    }

    
  }

}
