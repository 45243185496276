import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, SimpleChange, ViewChildren, QueryList } from '@angular/core';
import { Init } from '../../../inf/Init'
import { ImporterService } from 'src/app/services/importer-service';
import { ImporterSearchRequest } from 'src/app/models/ImporterSearchRequest';
import { Importer } from 'src/app/models/Importer';

@Component({
  selector: 'app-select-banned-company',
  templateUrl: './select-banned-company.component.html',
  styleUrls: ['./select-banned-company.component.css']
})
export class SelectBannedCompanyComponent implements OnInit {
  
  @Output() next:EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() prev:EventEmitter<string> = new EventEmitter<string>();
  @Input() importers: Importer[];
  allImporters: Importer[];
  isFiltered = false;

  pageSize = 25;
  itemCount:number;
  pageCount:number;
  pages:number[] = [];
  filteredImporters:Importer[];

  constructor(private importerService:ImporterService) {     
  }

  ngOnInit() 
  {
  }


  nextStep()
  {

    if(this.importers == null || this.importers.filter(x=> x.IsBanned == true).length == this.importers.length)
    {
      alert("Filtrelerinize uygun ithalatçı bulunamadı ! Filtrelerinizi gözden geçirerek tekrar arama yapınız.");
      return;
    }

    this.next.emit(["g","f","d"]);
}

  prevStep()
  {
    this.prev.emit("");
  }


  banImporter(bannedImporter:Importer)
  {
    this.importerService.banImporter(bannedImporter.Id).subscribe(data=>{
      bannedImporter.IsBanned = true;
    });
  }

  removeBanFromImporter(bannedImporter:Importer)
  {
    this.importerService.removeBanFromImporter(bannedImporter.Id).subscribe(data=>{
      bannedImporter.IsBanned = false;
    });
  }

  doFilter()
  {
    
    if(this.allImporters == undefined){
      this.allImporters = this.importers;
    }

    this.isFiltered = !this.isFiltered;

    if(this.isFiltered == true)
    {
      this.filteredImporters = this.allImporters.filter(x=> x.IsBanned == true);
    }else{
      this.loadImporters();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    
    let change = changes["importers"];
  
    if(change.currentValue != undefined)
    {
      
      this.loadImporters();
    }
  }

  loadImporters()
  {
    this.filteredImporters = this.importers.slice(0,this.pageSize);
    this.pageCount = Math.ceil(this.importers.length / this.pageSize);
    for (let i = 1; i < this.pageCount; i++) {
      this.pages.push(i);
    }
  }

  setPage(pageNumber:number)
  {
    this.filteredImporters = this.importers.slice((pageNumber-1)*this.pageSize,(pageNumber*this.pageSize) + this.pageSize) ;
  }

  search:string;

  filter(){
    if(this.search.length > 0){
      this.filteredImporters = this.importers.filter(x=> x.CompanyName.toLowerCase().startsWith(this.search.toLocaleLowerCase()));
    }else{
      this.loadImporters();
    }
    
  }



}
