import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Mailer } from 'src/app/models/Mailer';
import { MailerTestRequest } from 'src/app/models/MailerTestRequest';
import { MailerService } from 'src/app/services/mailer-service';
import { HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-generate-mail',
  templateUrl: './generate-mail.component.html',
  styleUrls: ['./generate-mail.component.css']
})
export class GenerateMailComponent implements OnInit {

  public mailer:Mailer;
  public testMailAddress:string;
  @Output() next:EventEmitter<Mailer> = new EventEmitter<Mailer>();
  @Output() prev:EventEmitter<string> = new EventEmitter<string>();
  saveDraft:boolean;
  constructor(private mailerService:MailerService) 
  { 
    this.files = [];
    this.mailer = new Mailer();
    
  }


  ngOnInit() {
    var localMail = localStorage.getItem("mailer");
    if(localMail != null)
    {
      this.mailer = JSON.parse(localMail);
    }
  }

  nextStep()
  {
    if( (this.mailer.Title != undefined && this.mailer.Title.trim() == "") || (this.mailer.SenderIdentity != undefined && this.mailer.SenderIdentity.trim() == "")
     || (this.mailer.SenderMailAdress != undefined && this.mailer.SenderMailAdress.trim() == "") ||
     (this.mailer.Content != undefined &&  this.mailer.Content.trim() == "")
    || this.mailer.Title == undefined || this.mailer.SenderIdentity == undefined || this.mailer.SenderMailAdress == undefined || this.mailer.Content == undefined) 
    {
      //TODO:Localize
      alert("Lütfen eksik alanları doldurun !");
      return;
    }

    this.mailer.Attachments = this.fileNames.map(x=> x.uploadedFileName);
    this.mailer.SaveDraft = this.saveDraft;
    this.next.emit(this.mailer);
  }

  prevStep()
  {
    this.prev.emit("");
  }


  files:File[];
  fileNames = [];

  fileChangeEvent(event)
  {
    let selectedFiles = Array.from<File>(event.target.files);
    selectedFiles.forEach(sf=>{
      var finded = this.files.find(x=>x.name == sf.name);
      if(finded == null)
      {

        if(sf.size > 7864320)
        {
          alert("Maximum dosya boyutu 7.5 MB");
        }else{
          
          this.files.push(sf);

          console.log("pushed");

          this.mailerService.upload([sf]).subscribe(event=>{
            
            console.log("uploading");
                      
            if (event.type === HttpEventType.Response)
            { 

              alert("Ekler sunucuya yüklendi");
              console.log("upload suceeded");

              var uf = new UploadedFile();
              uf.uploadedFileName = event.body.toString();
              uf.fileName = sf.name;
              this.fileNames.push(uf); 
            }

          });
        }
        
      }

    });
  }

  @ViewChild('fileInput') fileInput:ElementRef;

  openFileDialog(){
      let event = new MouseEvent('click', {bubbles: false});
      this.fileInput.nativeElement.dispatchEvent(event);
  }

  removeFile(file:File)
  {
    this.files = this.files.filter(x=> x.name != file.name);
    this.fileNames = this.fileNames.filter(x=> x.fileName != file.name);
  }

  testMailer()
  {
    var testRequest = new MailerTestRequest();
    testRequest.Content = this.mailer.Content;
    testRequest.SenderIdentity = this.mailer.SenderIdentity;
    testRequest.SenderMailAddress = this.mailer.SenderMailAdress;
    testRequest.Subject = this.mailer.Title;
    testRequest.MailAddress = this.testMailAddress;

    this.mailerService.testMailer(testRequest).subscribe(data=>{
      alert("Test maili gönderildi.");
    });

  }

 

}

export class UploadedFile{
  fileName:string;
  uploadedFileName:string;
}

