import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { HomeComponent } from './pages/home/home.component';
import { MailerComponent } from './pages/mailer-steps/mailer/mailer.component';
import { SelectSectorComponent } from './pages/mailer-steps/select-sector/select-sector.component';
import { SelectCountryComponent } from './pages/mailer-steps/select-country/select-country.component';
import { SelectBannedCompanyComponent } from './pages/mailer-steps/select-banned-company/select-banned-company.component';
import { GenerateMailComponent } from './pages/mailer-steps/generate-mail/generate-mail.component';
import { MailerRegisterComponent } from './pages/mailer-steps/mailer-register/mailer-register.component';
import { MailerLoginComponent } from './pages/mailer-steps/mailer-login/mailer-login.component';
import { MailerPaymentComponent } from './pages/mailer-steps/mailer-payment/mailer-payment.component';
import { MailerCompleteComponent } from './pages/mailer-steps/mailer-complete/mailer-complete.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AuthGuard } from './guards/auth.guard';
import { RegisterCompletedComponent } from './pages/register-completed/register-completed.component';
import { MailerPreviewComponent } from './pages/mailer-steps/mailer-preview/mailer-preview.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ContactComponent } from './pages/contact/contact.component';

const routes: Routes = [
  {path:'login',component:LoginComponent},
  {path:'register',component:RegisterComponent},
  {path:'register-completed', component:RegisterCompletedComponent},
  {path:'password-recovery',component:PasswordRecoveryComponent},
  {path:'password-reset/:userId/:token',component:PasswordResetComponent},

  {path:'',component:HomeComponent},
  {path:'index',component:HomeComponent},
  {path:'contact',component:ContactComponent},
  {path:'user-profile',component:UserProfileComponent, canActivate: [AuthGuard]},
  {path:'mailer-complete',component:MailerCompleteComponent, canActivate: [AuthGuard]},
  {path:'mailer-steps', component:MailerComponent, children:  [
    {path:'select-sector', component:SelectSectorComponent},
    {path:'select-country', component:SelectCountryComponent},
    {path:'select-banned-company',component:SelectBannedCompanyComponent, canActivate: [AuthGuard]},
    {path:'generate-mail',component:GenerateMailComponent, canActivate: [AuthGuard]},
    {path:'mailer-preview',component:MailerPreviewComponent, canActivate: [AuthGuard]},
    {path:'mailer-register', component:MailerRegisterComponent},
    {path:'mailer-login',component:MailerLoginComponent},
    {path:'mailer-payment',component:MailerPaymentComponent, canActivate: [AuthGuard]}
  ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
