import { Component, OnInit } from '@angular/core';
import {RouterModule} from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication-service.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language-service';
import { Language } from 'src/app/models/Language';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  languages:Language[];
  defaultLang:any;
  constructor(private authService:AuthenticationService, private translate:TranslateService, private languageService:LanguageService) { }

  ngOnInit() {
    this.LoadLanguages();
    this.useLang(1);
    this.defaultLang = 1;
  }

  isAuthenticated():boolean
  {
    return this.authService.isAuthenticated();
  }

  useLang(id)
  {
    this.translate.setDefaultLang(id);
    this.translate.use(id);
  }

  LoadLanguages()
  {
    this.languageService.getLanguages().subscribe(response=>{
      this.languages = response;
    });
  }

}
