import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../models/Country';
import { ServiceBase } from '../inf/ServiceBase';

@Injectable({providedIn: 'root'})
export class CountryService implements ServiceBase {
    constructor(private httpClient: HttpClient) { }
    
    getCountry():Observable<Country[]>
    {
        return this.httpClient.get<Country[]>(ServiceBase.importerService+"Country");
    }

}